<template>
    <div class="page-rooter-fff">
        <div class="box">
            <div><img style="width: 30vw;height: 30vw;border-radius: 50%" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/coach/logo.jpg" alt=""></div>
            <div style="margin-top: 5.33vw">请使用 <span style="color: red">超鹿运动APP/微信</span> 扫码打开</div>
        </div>
    </div>
</template>

<script>
import wx from "weixin-js-sdk"
import appMixin from "@/mixin/appMixin";
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import {
    appGetCityId,
    appGetUser,
    appGetToken,
    appType
} from "../lib/appMethod";

export default {
    data() {
        return {
            appTypeStr:'',
            time:3,
            interver:'',
            isUndo:'',
            caseStr:'',
        };
    },
    mixins: [appMixin],
    created() {
        this.appTypeStr=appType()
        this.load();
    },
    mounted() {},
    methods: {
        // 页面初次加载
        async load() {
            if (this.appTypeStr === 'ios' || this.appTypeStr === 'and'){
                try{
                    this.appCommParams = await this.getAppParams([
                        appGetCityId(),
                        appGetUser(),
                        appGetToken(),
                    ]);
                    console.log(this.appCommParams);
                }catch(err){
                    console.log('不是APP环境环境错误')
                }
            }else if(this.appTypeStr === 'mini'){
                console.log('mini')
            }
        },
    },
};
</script>
<style scoped>
    .box{
        width: 100vw;
        height: 100vh;
        padding-top: 20vh;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        /*justify-content: center;*/
        flex-direction: column;
    }
</style>

